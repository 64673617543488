import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '@/components/loading-screen';
//
import Login from '@/pages/auth/LoginPage';
import { useAuthContext } from './useAuthContext';
import { usePermission } from './usePermission';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
  permission?: string;
};

export default function AuthGuard({ children, permission }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const { isGranted } = usePermission();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (permission) {
    if (isGranted(permission)) {
      return <>{children}</>;
    } else {
      return <Navigate to="/404" replace />;
    }
  }

  return <>{children}</>;
}
