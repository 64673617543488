import { useAppContext } from 'src/app/useAppContext';

type ReturnType = {
  isGranted(params: string): boolean;
};

export function usePermission(): ReturnType {
  const appContext = useAppContext();
  const isGranted = (permissionName: string) =>
    appContext.auth.allPermissions[permissionName] !== undefined &&
    appContext.auth.grantedPermissions[permissionName] !== undefined;

  return { isGranted };
}
