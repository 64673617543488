import { create } from 'zustand';

interface QuestionStore {
  questionId: number;
  setQuestionId: (questionId: number) => void;
}

export const useQuestionStore = create<QuestionStore>()(set => ({
  questionId: 0,
  setQuestionId: id => set(() => ({ questionId: id })),
}));
