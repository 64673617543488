// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

// const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  // root: ROOTS_AUTH,
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  newPassword: '/new-password',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, 'home'),
  accountSettings: path(ROOTS_DASHBOARD, 'account-settings'),
  admin: {
    users: {
      root: path(ROOTS_DASHBOARD, 'admin/users'),
      new: path(ROOTS_DASHBOARD, 'admin/users/new'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `admin/users/edit/${id}`),
    },
    roles: {
      root: path(ROOTS_DASHBOARD, 'admin/roles'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `admin/roles/edit/${id}`),
    },
  },
  myGoals: path(ROOTS_DASHBOARD, 'mygoals'),
  assessments: path(ROOTS_DASHBOARD, 'assessment'),
  assessmentDetail: (id: number) => path(ROOTS_DASHBOARD, `assessment/${id}`),
  reports: path(ROOTS_DASHBOARD, `reports`),
  reviews: path(ROOTS_DASHBOARD, `reviews`),
  report: (id: number) => path(ROOTS_DASHBOARD, `reports/${id}`),
  review: (id: number) => path(ROOTS_DASHBOARD, `review/${id}`),
  myDoubt: path(ROOTS_DASHBOARD, 'my-doubts'),
  myReviseList: path(ROOTS_DASHBOARD, 'my-revise-list'),
  unlock: path(ROOTS_DASHBOARD, 'unlock'),
  tipOfDay: path(ROOTS_DASHBOARD, 'tip-of-day'),
};
