import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '@/components/loading-screen';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const Login = Loadable(lazy(() => import('@/pages/auth/LoginPage')));
export const Register = Loadable(lazy(() => import('@/pages/auth/RegisterPage')));
export const ForgotPassword = Loadable(lazy(() => import('@/pages/auth/ForgotPasswordPage')));
export const NewPassword = Loadable(lazy(() => import('@/pages/auth/NewPasswordPage')));

// MAIN
export const Dashboard = Loadable(lazy(() => import('@/pages/Dashboard')));
export const UserAccount = Loadable(lazy(() => import('@/pages/user/UserAccount')));
export const Landing = Loadable(lazy(() => import('@/pages/Landing')));

// ADMIN
export const Users = Loadable(lazy(() => import('@/pages/admin/users/Users')));
export const CreateUser = Loadable(lazy(() => import('@/pages/admin/users/CreateUser')));
export const EditUser = Loadable(lazy(() => import('@/pages/admin/users/EditUser')));
export const Roles = Loadable(lazy(() => import('@/pages/admin/roles/Roles')));
export const EditRole = Loadable(lazy(() => import('@/pages/admin/roles/EditRole')));

// LEARNER
export const MyGoals = Loadable(lazy(() => import('@/pages/learner/MyGoals')));
export const PreAssessment = Loadable(lazy(() => import('@/pages/learner/PreAssessment')));
export const Assessment = Loadable(lazy(() => import('@/pages/learner/Assessment')));
export const Reports = Loadable(lazy(() => import('@/pages/learner/Reports')));
export const ReportDetail = Loadable(lazy(() => import('@/pages/learner/ReportDetail')));
export const Reviews = Loadable(lazy(() => import('@/pages/learner/Reviews')));
export const ReviewDetail = Loadable(lazy(() => import('@/pages/learner/ReviewDetail')));

export const UnlockFeature = Loadable(lazy(() => import('@/pages/learner/UnlockFeature')));
export const MyDoubts = Loadable(lazy(() => import('@/pages/learner/MyDoubts')));
export const MyDoubtsV2 = Loadable(lazy(() => import('@/pages/learner/MyDoubtsv2')));
export const MyReviseList = Loadable(lazy(() => import('@/pages/learner/MyReviseList')));
export const Unlock2 = Loadable(lazy(() => import('@/pages/learner/Unlock2')));

export const TipOfDay = Loadable(lazy(() => import('@/pages/learner/TipOfDay')));
export const Calendar = Loadable(lazy(() => import('@/pages/learner/Calendar')));

export const Page404 = Loadable(lazy(() => import('@/pages/Page404')));
