import { useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// auth
import ReactGA from 'react-ga4';
import { useAuthContext, GuestGuard, AuthGuard } from '@/auth';
// layouts
import { DashboardLayout } from '@/layouts/dashboard';
import { GOOGLE_ANALYTICS } from '@/config';
import MainLayout from '@/layouts/main';
import CompactLayout from '../layouts/compact';
// import DashboardLayout from '../layouts/dashboard';
// config
// import { PATH_AFTER_LOGIN } from '../config';
//
import {
  Page404,
  Landing,
  Dashboard,
  Users,
  CreateUser,
  EditUser,
  Roles,
  EditRole,
  MyGoals,
  Assessment,
  PreAssessment,
  MyDoubts,
  MyReviseList,
  UnlockFeature,
  Login,
  Register,
  ForgotPassword,
  NewPassword,
  UserAccount,
  Reviews,
  MyDoubtsV2,
  TipOfDay,
  Calendar,
  Reports,
  ReportDetail,
  ReviewDetail,
  Unlock2,
} from './elements';

// ----------------------------------------------------------------------

import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

export default function Router() {
  const { isAuthenticated } = useAuthContext();
  const location = useLocation();

  useEffect(() => {
    if (GOOGLE_ANALYTICS) {
      ReactGA.initialize(GOOGLE_ANALYTICS);
    }
  }, []);

  useEffect(() => {
    if (GOOGLE_ANALYTICS) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [location]);

  const publicRoutes = [
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   // element: <Navigate to="/login" replace />,
    //   children: [
    //     // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { element: <HomePage />, index: true },
    //   ],
    // },
    // {
    //   path: '/login',
    //   element: (
    //     <GuestGuard>
    //       <LoginPage />
    //     </GuestGuard>
    //   ),
    // },
    {
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <Landing />,
        },
      ],
    },

    {
      path: '/login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        // { path: 'verify', element: <VerifyCodePage /> },
      ],
    },
    {
      path: '/register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      element: <CompactLayout />,
      children: [{ path: '*', element: <Page404 /> }],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ];

  const protectedRoutes = [
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_DASHBOARD.home} replace />, index: true },
        { path: 'home', element: <Dashboard />, index: true },
        { path: 'account-settings', element: <UserAccount />, index: true },

        // ADMIN
        {
          path: 'admin',
          children: [
            // USERS
            {
              path: 'users',
              element: (
                <AuthGuard permission="Administration.Users">
                  <Users />
                </AuthGuard>
              ),
            },
            {
              path: 'users/new',
              element: (
                <AuthGuard permission="Administration.Users.Create">
                  <CreateUser />
                </AuthGuard>
              ),
            },
            {
              path: 'users/edit/:id',
              element: (
                <AuthGuard permission="Administration.Users.Edit">
                  <EditUser />
                </AuthGuard>
              ),
            },
            // ROLES
            {
              path: 'roles',
              element: (
                <AuthGuard permission="Administration.Roles">
                  <Roles />
                </AuthGuard>
              ),
            },
            {
              path: 'roles/edit/:id',
              element: (
                <AuthGuard permission="Administration.Roles.Edit">
                  <EditRole />
                </AuthGuard>
              ),
            },
          ],
        },

        // Learner
        {
          path: 'mygoals',
          element: (
            <AuthGuard permission="Learner">
              <MyGoals />
            </AuthGuard>
          ),
        },
        {
          path: 'assessment',
          element: (
            <AuthGuard permission="Learner">
              <PreAssessment />
            </AuthGuard>
          ),
        },
        {
          path: 'assessment/:id',
          element: (
            <AuthGuard permission="Learner">
              <Assessment />
            </AuthGuard>
          ),
        },
        {
          path: 'reports',
          element: (
            <AuthGuard permission="Learner">
              <Reports />
            </AuthGuard>
          ),
        },
        {
          path: 'reports/:id',
          element: (
            <AuthGuard permission="Learner">
              <ReportDetail />
            </AuthGuard>
          ),
        },
        {
          path: 'reviews',
          element: (
            <AuthGuard permission="Learner">
              <Reviews />
            </AuthGuard>
          ),
        },
        {
          path: 'review/:id',
          element: (
            <AuthGuard permission="Learner">
              <ReviewDetail />
            </AuthGuard>
          ),
        },
        {
          path: 'my-doubts-old',
          element: (
            <AuthGuard permission="Learner">
              <MyDoubts />
            </AuthGuard>
          ),
        },
        {
          path: 'my-doubts',
          element: (
            <AuthGuard permission="Learner">
              <MyDoubtsV2 />
            </AuthGuard>
          ),
        },
        {
          path: 'my-revise-list',
          element: (
            <AuthGuard permission="Learner">
              <MyReviseList />
            </AuthGuard>
          ),
        },
        {
          path: 'unlock',
          element: (
            <AuthGuard permission="Learner">
              <UnlockFeature />
            </AuthGuard>
          ),
        },
        {
          path: 'unlock2',
          element: (
            <AuthGuard permission="Learner">
              <Unlock2 />
            </AuthGuard>
          ),
        },
        {
          path: 'tip-of-day',
          element: (
            <AuthGuard permission="Learner">
              <TipOfDay />
            </AuthGuard>
          ),
        },
        {
          path: 'calendar',
          element: (
            <AuthGuard permission="Learner">
              <Calendar />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '*', element: <Page404 /> }],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ];

  const routes = isAuthenticated ? protectedRoutes : publicRoutes;

  return useRoutes(routes);
}
