import { Box, Button, Container, Stack } from '@mui/material';

export default function AppError() {
  return (
    <>
      <Box sx={{ height: '97vh' }}>
        <Container sx={{ height: 'inherit' }}>
          <Stack sx={{ height: 'inherit' }} justifyContent={'center'} alignItems={'center'}>
            <h2>Ooops, something went wrong</h2>
            <Button onClick={() => window.location.reload()} size="large" variant="contained">
              Refresh
            </Button>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
