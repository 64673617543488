// @mui
import { List, Stack } from '@mui/material';
// locales
import RequirePermission from '@/auth/RequirePermission';
import { useLocales } from '../../../locales';
//
import { NavSectionProps } from '../types';
import { StyledSubheader } from './styles';
import NavList from './NavList';
// auth

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { t } = useLocales();

  return (
    <Stack sx={sx} {...other}>
      {data.map(group => {
        const key = group.subheader || group.items[0].title;

        return (
          <RequirePermission key={key} permission={group.permission}>
            <List key={key} disablePadding sx={{ px: 2 }}>
              {group.subheader && (
                <StyledSubheader disableSticky>{`${t(group.subheader)}`}</StyledSubheader>
              )}

              {group.items.map(list => (
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChild={!!list.children}
                />
              ))}
            </List>
          </RequirePermission>
        );
      })}
    </Stack>
  );
}
