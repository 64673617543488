import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';
import Image from '@/components/image';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

// eslint-disable-next-line react/display-name
const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    // const theme = useTheme();

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    // const PRIMARY_MAIN = theme.palette.primary.main;

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 30,
          height: 30,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <Image src={'/assets/images/logo4.png'} sx={{ mx: 'auto' }} />
        {/* <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="100%"
          viewBox="0 0 299 300"
          enableBackground="new 0 0 299 300"
          // xml:space="preserve"
        >
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M300.000000,194.000000 
	C300.000000,229.653152 300.000000,265.306305 300.000000,300.979736 
	C200.384644,300.979736 100.769272,300.979736 1.076954,300.979736 
	C1.076954,201.089264 1.076954,101.178452 1.076954,1.133817 
	C100.666557,1.133817 200.333252,1.133817 299.999969,1.126323 
	C300.000000,50.433449 300.000000,99.748062 299.642395,149.447845 
	C298.912018,149.313324 298.355072,148.845169 298.193176,148.266510 
	C292.850433,129.167831 282.393921,113.540771 266.768524,101.285805 
	C253.130661,90.589661 237.621948,84.049965 220.586716,82.211861 
	C201.650818,80.168678 183.176163,83.040176 166.171677,92.236572 
	C150.085022,100.936584 137.110535,112.957069 128.702087,129.424561 
	C126.983879,132.789597 124.864220,134.176544 120.958931,134.091705 
	C109.138458,133.834915 97.306610,133.905106 85.484550,134.129715 
	C83.703880,134.163528 80.494537,135.604446 80.428947,136.564163 
	C80.069954,141.816986 79.538147,147.443100 81.157394,152.233994 
	C81.712639,153.876785 88.311882,153.520233 92.161232,153.982254 
	C93.474838,154.139923 94.825165,153.961029 96.155502,154.015091 
	C102.760971,154.283447 109.365364,154.578186 115.661179,155.166840 
	C115.257935,167.598343 115.147324,179.726593 115.109207,191.855072 
	C115.106880,192.595383 115.678902,193.337479 115.810715,194.373291 
	C114.589203,195.446579 113.647919,196.491837 112.475807,196.963547 
	C98.102196,202.748352 83.431190,203.727798 68.290627,200.182053 
	C44.205681,194.541641 32.368401,177.975967 28.131317,155.063828 
	C25.187733,139.146301 25.975140,123.119568 33.575958,108.523582 
	C37.408905,101.163109 42.372082,93.559181 48.758621,88.514114 
	C69.167259,72.392212 92.302437,72.939133 115.865387,80.544891 
	C121.426514,82.339935 126.770439,84.807838 133.005524,87.279922 
	C133.005524,81.736061 132.491882,76.870819 133.136292,72.164146 
	C133.931595,66.355293 131.284195,63.573860 126.326370,61.974491 
	C123.368103,61.020168 120.477997,59.665932 117.445747,59.160889 
	C100.557831,56.348080 83.621521,53.938862 66.699570,58.901684 
	C61.960564,60.291531 57.177086,61.696720 52.669598,63.676720 
	C40.633533,68.963806 30.696081,76.916473 22.228662,87.093697 
	C12.710853,98.533409 7.536888,111.774681 5.300922,126.045395 
	C4.010586,134.280777 4.685578,142.886292 5.032466,151.302277 
	C5.646646,166.203110 10.560279,179.749542 19.496937,191.674088 
	C32.620495,209.185394 50.257393,219.118591 72.022293,220.876266 
	C90.040886,222.331406 107.775009,220.378693 124.800163,213.684021 
	C125.816986,213.284180 127.990425,213.935104 128.645676,214.821518 
	C132.177597,219.599472 135.073853,224.868256 138.785965,229.488403 
	C153.846176,248.232513 174.135590,258.346344 197.360809,261.631744 
	C211.392731,263.616669 225.658203,262.177490 239.496597,257.250885 
	C266.986908,247.464005 285.864349,229.308258 296.078918,202.014114 
	C297.116394,199.241867 298.680359,196.666656 300.000000,194.000000 
z"
          />
          <path
            fill="#3B3838"
            opacity="1.000000"
            stroke="none"
            d="
M300.000000,193.531342 
	C298.680359,196.666656 297.116394,199.241867 296.078918,202.014114 
	C285.864349,229.308258 266.986908,247.464005 239.496597,257.250885 
	C225.658203,262.177490 211.392731,263.616669 197.360809,261.631744 
	C174.135590,258.346344 153.846176,248.232513 138.785965,229.488403 
	C135.073853,224.868256 132.177597,219.599472 128.645676,214.821518 
	C127.990425,213.935104 125.816986,213.284180 124.800163,213.684021 
	C107.775009,220.378693 90.040886,222.331406 72.022293,220.876266 
	C50.257393,219.118591 32.620495,209.185394 19.496937,191.674088 
	C10.560279,179.749542 5.646646,166.203110 5.032466,151.302277 
	C4.685578,142.886292 4.010586,134.280777 5.300922,126.045395 
	C7.536888,111.774681 12.710853,98.533409 22.228662,87.093697 
	C30.696081,76.916473 40.633533,68.963806 52.669598,63.676720 
	C57.177086,61.696720 61.960564,60.291531 66.699570,58.901684 
	C83.621521,53.938862 100.557831,56.348080 117.445747,59.160889 
	C120.477997,59.665932 123.368103,61.020168 126.326370,61.974491 
	C131.284195,63.573860 133.931595,66.355293 133.136292,72.164146 
	C132.491882,76.870819 133.005524,81.736061 133.005524,87.279922 
	C126.770439,84.807838 121.426514,82.339935 115.865387,80.544891 
	C92.302437,72.939133 69.167259,72.392212 48.758621,88.514114 
	C42.372082,93.559181 37.408905,101.163109 33.575958,108.523582 
	C25.975140,123.119568 25.187733,139.146301 28.131317,155.063828 
	C32.368401,177.975967 44.205681,194.541641 68.290627,200.182053 
	C83.431190,203.727798 98.102196,202.748352 112.475807,196.963547 
	C113.647919,196.491837 114.589203,195.446579 115.812775,193.899307 
	C115.981911,180.375061 115.976021,167.619354 115.970123,154.863632 
	C109.365364,154.578186 102.760971,154.283447 96.155502,154.015091 
	C94.825165,153.961029 93.474838,154.139923 92.161232,153.982254 
	C88.311882,153.520233 81.712639,153.876785 81.157394,152.233994 
	C79.538147,147.443100 80.069954,141.816986 80.428947,136.564163 
	C80.494537,135.604446 83.703880,134.163528 85.484550,134.129715 
	C97.306610,133.905106 109.138458,133.834915 120.958931,134.091705 
	C124.864220,134.176544 126.983879,132.789597 128.702087,129.424561 
	C137.110535,112.957069 150.085022,100.936584 166.171677,92.236572 
	C183.176163,83.040176 201.650818,80.168678 220.586716,82.211861 
	C237.621948,84.049965 253.130661,90.589661 266.768524,101.285805 
	C282.393921,113.540771 292.850433,129.167831 298.193176,148.266510 
	C298.355072,148.845169 298.912018,149.313324 299.642395,149.916504 
	C300.000000,164.354233 300.000000,178.708450 300.000000,193.531342 
M136.986755,176.488007 
	C137.800262,183.872223 137.213287,191.778122 139.727829,198.528717 
	C144.508652,211.363266 153.136154,221.689865 164.100616,230.374924 
	C181.979889,244.537277 202.491959,247.837189 223.742325,244.235443 
	C247.119843,240.273178 264.764465,226.798691 275.866364,205.375137 
	C288.367096,181.252258 287.087311,157.369141 273.585876,134.374405 
	C269.339447,127.142189 262.986328,120.764015 256.465302,115.361473 
	C250.342300,110.288727 243.062027,106.203125 235.683304,103.153351 
	C225.044952,98.756294 213.553299,97.277161 202.110931,99.144814 
	C194.038681,100.462395 185.975769,102.530113 178.278839,105.293800 
	C166.391159,109.562218 157.417709,118.158714 149.839020,127.897461 
	C144.179321,135.170242 138.742142,142.969788 137.911606,152.617126 
	C137.257736,160.212418 137.270218,167.865067 136.986755,176.488007 
z"
          />
          <path
            fill="#484848"
            opacity="1.000000"
            stroke="none"
            d="
M115.661179,155.166840 
	C115.976021,167.619354 115.981911,180.375061 115.985741,193.604767 
	C115.678902,193.337479 115.106880,192.595383 115.109207,191.855072 
	C115.147324,179.726593 115.257935,167.598343 115.661179,155.166840 
z"
          />
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M136.986740,175.990128 
	C137.270218,167.865067 137.257736,160.212418 137.911606,152.617126 
	C138.742142,142.969788 144.179321,135.170242 149.839020,127.897461 
	C157.417709,118.158714 166.391159,109.562218 178.278839,105.293800 
	C185.975769,102.530113 194.038681,100.462395 202.110931,99.144814 
	C213.553299,97.277161 225.044952,98.756294 235.683304,103.153351 
	C243.062027,106.203125 250.342300,110.288727 256.465302,115.361473 
	C262.986328,120.764015 269.339447,127.142189 273.585876,134.374405 
	C287.087311,157.369141 288.367096,181.252258 275.866364,205.375137 
	C264.764465,226.798691 247.119843,240.273178 223.742325,244.235443 
	C202.491959,247.837189 181.979889,244.537277 164.100616,230.374924 
	C153.136154,221.689865 144.508652,211.363266 139.727829,198.528717 
	C137.213287,191.778122 137.800262,183.872223 136.986740,175.990128 
M239.011536,157.507874 
	C241.930649,150.824677 244.890747,144.158691 247.712296,137.434570 
	C248.032394,136.671753 247.900009,135.267838 247.384338,134.731735 
	C246.862747,134.189514 245.473450,133.995834 244.692047,134.278732 
	C242.110504,135.213333 239.644653,136.462341 237.111664,137.537048 
	C224.108948,143.053925 211.177551,148.752380 198.046646,153.945923 
	C193.797043,155.626709 191.616638,158.326172 189.972687,162.306305 
	C185.852936,172.280624 181.319489,182.083618 177.164078,192.043823 
	C174.935379,197.385880 173.144547,202.910599 170.539505,210.040588 
	C177.021484,207.716309 182.302521,205.944550 187.491577,203.935226 
	C196.742386,200.353088 206.009949,196.796127 215.124985,192.889130 
	C219.545227,190.994492 225.557144,189.375168 227.494797,185.809021 
	C232.205765,177.138687 234.955185,167.402557 239.011536,157.507874 
z"
          />
          <path
            fill="#B71D18"
            opacity="1.000000"
            stroke="none"
            d="
M238.753143,157.801819 
	C234.955185,167.402557 232.205765,177.138687 227.494797,185.809021 
	C225.557144,189.375168 219.545227,190.994492 215.124985,192.889130 
	C206.009949,196.796127 196.742386,200.353088 187.491577,203.935226 
	C182.302521,205.944550 177.021484,207.716309 170.539505,210.040588 
	C173.144547,202.910599 174.935379,197.385880 177.164078,192.043823 
	C181.319489,182.083618 185.852936,172.280624 189.972687,162.306305 
	C191.616638,158.326172 193.797043,155.626709 198.046646,153.945923 
	C211.177551,148.752380 224.108948,143.053925 237.111664,137.537048 
	C239.644653,136.462341 242.110504,135.213333 244.692047,134.278732 
	C245.473450,133.995834 246.862747,134.189514 247.384338,134.731735 
	C247.900009,135.267838 248.032394,136.671753 247.712296,137.434570 
	C244.890747,144.158691 241.930649,150.824677 238.753143,157.801819 
M212.489197,173.835739 
	C212.620132,172.334991 212.751053,170.834244 212.968842,168.337784 
	C210.154556,169.206467 208.210510,169.659927 206.449905,170.498535 
	C206.166260,170.633652 206.395767,173.186035 206.913223,173.410538 
	C208.434021,174.070328 210.219208,174.120728 212.489197,173.835739 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M212.195038,174.122742 
	C210.219208,174.120728 208.434021,174.070328 206.913223,173.410538 
	C206.395767,173.186035 206.166260,170.633652 206.449905,170.498535 
	C208.210510,169.659927 210.154556,169.206467 212.968842,168.337784 
	C212.751053,170.834244 212.620132,172.334991 212.195038,174.122742 
z"
          />
        </svg> */}
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 250 190">
          <defs>
            <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
              <stop offset="0%" stopColor={PRIMARY_DARK} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>
          </defs>

          <g
            fill={PRIMARY_MAIN}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
            transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)"
          >
            <path
              d="M1690 1771 c-74 -24 -136 -77 -173 -146 -16 -30 -21 -58 -21 -115 0
-70 4 -83 57 -199 31 -68 54 -127 51 -130 -3 -3 -59 -7 -125 -8 -66 0 -125 -3
-133 -6 -20 -8 -46 -70 -39 -95 8 -30 41 -28 75 5 l28 27 0 -97 c0 -86 -2 -98
-20 -109 -32 -21 -25 -46 18 -57 106 -30 221 13 152 57 -18 11 -20 23 -20 107
0 52 4 95 9 95 5 0 16 -11 25 -25 19 -29 60 -34 74 -8 8 13 18 -2 50 -70 26
-55 47 -87 56 -87 30 0 254 488 263 572 15 149 -97 284 -244 294 -32 2 -69 0
-83 -5z m156 -67 c79 -38 132 -134 121 -219 -5 -36 -85 -224 -177 -419 l-33
-69 -23 46 c-13 25 -61 128 -106 229 -71 158 -82 190 -82 238 0 84 43 153 119
193 40 21 137 22 181 1z"
            />
            <path
              d="M1702 1603 c-60 -29 -77 -93 -42 -151 34 -56 103 -68 161 -29 68 46
61 145 -14 180 -43 21 -63 21 -105 0z m92 -54 c31 -24 33 -52 6 -79 -35 -35
-90 -21 -105 27 -4 17 1 29 19 48 31 30 45 31 80 4z"
            />
            <path
              d="M458 1419 l-258 -129 0 -595 0 -595 257 129 258 129 260 -130 260
-130 260 130 260 130 257 -129 258 -129 0 595 0 595 -99 49 -98 50 -12 -25
c-10 -23 -8 -26 74 -66 l85 -42 0 -533 c0 -352 -3 -533 -10 -533 -6 0 -105 47
-220 105 l-210 105 0 220 0 220 -25 0 -25 0 0 -218 0 -217 -235 -118 -235
-117 0 538 0 537 95 48 c52 26 95 50 95 54 0 5 -5 15 -10 23 -8 13 -24 8 -105
-33 l-95 -47 -260 130 c-143 72 -261 130 -262 129 -2 0 -119 -58 -260 -130z
m232 -484 l0 -535 -210 -105 c-115 -58 -214 -105 -220 -105 -7 0 -10 181 -10
533 l0 532 213 107 c116 59 215 107 220 107 4 1 7 -240 7 -534z m295 423 l225
-113 0 -537 0 -538 -235 117 -235 118 0 532 c0 347 3 533 10 533 5 0 111 -51
235 -112z"
            />
            <path
              d="M447 1170 c-40 -12 -94 -56 -112 -90 -8 -16 -15 -51 -15 -78 0 -96
97 -181 195 -169 62 6 118 37 129 70 15 42 -2 52 -57 33 -80 -27 -137 15 -137
101 0 75 52 86 110 23 20 -22 44 -40 53 -40 26 0 50 45 42 79 -9 40 -28 54
-94 68 -66 14 -77 14 -114 3z"
            />
            <path
              d="M948 1173 c-129 -4 -158 -16 -119 -50 19 -16 21 -28 21 -126 0 -86
-3 -109 -15 -113 -8 -4 -15 -14 -15 -24 0 -16 13 -18 143 -22 78 -2 148 0 154
5 7 5 18 27 25 49 10 33 9 41 -5 55 -16 16 -19 15 -51 -21 -30 -32 -40 -37
-73 -34 -37 3 -38 4 -41 46 -2 33 0 42 13 42 8 0 20 -11 25 -25 20 -52 50 -15
50 62 0 27 -5 54 -11 60 -13 13 -39 6 -39 -11 0 -18 -21 -35 -36 -29 -15 5
-18 40 -8 67 10 26 68 20 103 -10 26 -21 34 -24 46 -14 30 25 4 103 -33 98 -9
-1 -69 -4 -134 -5z"
            />
            <path
              d="M783 754 c-18 -8 -16 -43 2 -50 12 -5 15 -28 15 -115 0 -88 -3 -111
-15 -115 -20 -8 -19 -40 1 -48 28 -11 80 -6 99 8 19 14 19 15 2 34 -13 14 -17
32 -15 63 l3 44 38 -73 c21 -40 42 -70 48 -67 5 4 25 37 44 73 l34 67 1 -43
c0 -23 -6 -51 -12 -63 -21 -33 13 -52 85 -47 73 5 87 18 64 57 -23 40 -25 202
-2 221 9 7 15 23 13 34 -2 17 -11 22 -47 24 -73 5 -83 -1 -119 -74 -18 -36
-35 -64 -39 -61 -3 3 -22 34 -42 69 l-36 63 -55 2 c-30 1 -61 -1 -67 -3z"
            />
            <path
              d="M1424 756 c-14 -10 -23 -31 -27 -58 -8 -60 -73 -198 -105 -225 -26
-22 -27 -23 -8 -38 23 -17 88 -20 117 -5 23 13 25 36 4 44 -33 12 -12 26 39
26 54 0 82 -14 52 -26 -24 -9 -19 -33 10 -44 33 -13 115 -13 148 0 28 10 35
37 12 46 -8 3 -41 60 -74 127 -33 67 -68 130 -78 139 -29 25 -66 31 -90 14z
m56 -177 c0 -5 -13 -9 -30 -9 -34 0 -34 0 -18 36 l12 26 18 -22 c10 -12 18
-26 18 -31z"
            />
            <path
              d="M1843 753 c-20 -7 -15 -31 7 -43 18 -10 20 -21 20 -119 0 -88 -3
-110 -15 -115 -24 -9 -18 -36 11 -46 55 -21 154 -6 154 24 0 7 -7 19 -15 26
-8 7 -15 25 -15 41 0 28 2 29 47 29 117 0 166 132 71 190 -28 17 -50 20 -143
19 -60 0 -116 -3 -122 -6z m192 -98 c0 -34 -3 -40 -22 -43 -21 -3 -23 1 -23
43 0 42 2 46 23 43 19 -3 22 -9 22 -43z"
            />
            
          </g>
        </svg> */}
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
