import { useTranslation } from 'react-i18next';
// components
import { useAppContext } from '@/app/useAppContext';
import { useSettingsContext } from '../components/settings';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { localization } = useAppContext();
  const { i18n, t: translate } = useTranslation();

  const { onChangeDirectionByLang } = useSettingsContext();

  const langStorage = typeof window !== 'undefined' ? localStorage.getItem('i18nextLng') : '';

  const allLangs = localization?.languages ? localization.languages : [];

  const defaultLang = (allLangs.length > 0 && allLangs.find(_lang => _lang.isDefault)) || {
    name: 'en',
    displayName: 'English',
    icon: '',
    isDefault: true,
    isDisabled: false,
  };

  const currentLang = allLangs?.find(_lang => _lang.name === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    t: (text: any, options?: any) => `${translate(text, options)}`,
    currentLang,
    allLangs,
  };
}
