/* eslint-disable no-unused-vars */
import { createContext, useEffect, useReducer, useCallback } from 'react';
//
import { AuthenticateModel, SessionApi, TokenAuthApi, UserLoginInfoDto } from '@/api';
import LoadingScreen from '@/components/loading-screen';
import { isValidToken, setSession } from '../utils/session';
import { ActionMapType, AuthStateType, JWTContextType } from './types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: UserLoginInfoDto | null;
  };
  [Types.LOGIN]: undefined;
  [Types.REGISTER]: undefined;
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        // setSession(accessToken);

        const response = await new SessionApi().getCurrentLoginInformations();

        const { user } = response;

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (email: string, password: string) => {
    const response = await new TokenAuthApi().authenticate(
      new AuthenticateModel({
        userNameOrEmailAddress: email,
        password: password,
        rememberClient: false,
      })
    );
    const { accessToken } = response;

    setSession(accessToken);

    dispatch({
      type: Types.LOGIN,
    });

    window.location.assign('/');
  };

  // REGISTER
  const register = async (accessToken: string) => {
    setSession(accessToken);

    dispatch({
      type: Types.REGISTER,
    });

    window.location.assign('/mygoals?ft=1');
  };

  // LOGOUT
  const logout = async () => {
    setSession(undefined);
    dispatch({
      type: Types.LOGOUT,
    });
    window.location.assign('/');
  };

  if (state.isInitialized) {
    return (
      <AuthContext.Provider
        value={{
          ...state,
          method: 'jwt',
          login,
          loginWithGoogle: () => {},
          loginWithGithub: () => {},
          loginWithTwitter: () => {},
          logout,
          register,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  } else {
    return <LoadingScreen />;
  }
}
