// @mui

import { Typography, Stack, Card, CardContent, useTheme, Box } from '@mui/material';
// components

import { Logo, LogoText } from '@/components/logo';
// import Image from '@/components/image';
//
// import LandingMAP from '@/sections/landing/LandingMAP';
import { CustomAvatar } from '@/components/custom-avatar';

import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';
// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, title }: Props) {
  //const styles = useStyles();
  const theme = useTheme();
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection>
        <LogoText></LogoText>
        <Typography variant="h3" sx={{ mb: 5, maxWidth: 480, textAlign: 'center' }}>
          {title || 'Hi, Welcome back'}
        </Typography>

        {/* <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/illustrations/illustration_dashboard.png'}
          sx={{ maxWidth: 720 }}
        /> */}

        <StyledSectionBg />

        <Stack
          spacing={1}
          sx={{
            textAlign: 'center',
            mb: { xs: 2, md: 2 },
          }}
        >
          <Typography
            variant="h3"
            fontFamily="monospace"
            textTransform="uppercase"
            fontWeight="200"
            textAlign="center"
            sx={{ m: 1 }}
          >
            Get an edge over others
          </Typography>

          <Typography
            variant="h3"
            fontFamily="monospace"
            textTransform="uppercase"
            fontWeight="200"
            textAlign="center"
            sx={{ mb: 1 }}
          >
            In 3 simple steps
          </Typography>
        </Stack>
        <Box
          gap={{ xs: 3 }}
          display="grid"
          // alignItems="center"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
        >
          {/* className={styles.root} */}
          <Card elevation={0}>
            <CardContent>
              {/* <Typography sx={{ textAlign: 'center' }}>Where you rank?</Typography> */}
              <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                <CustomAvatar name="M" sx={{ backgroundColor: theme.palette.error.dark }} />
                <Typography variant="h5" fontWeight={400}>
                  Measure Performance
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ ml: 7 }} spacing={1}>
                <Typography>with </Typography>
                <Typography color={theme.palette.primary.main}>Smart Test</Typography>
                <Typography variant="h5" fontWeight="400"></Typography>
              </Stack>
              <Stack sx={{ ml: 6 }}>
                <Typography>First set your goals and find out where you rank</Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                <CustomAvatar name="A" sx={{ backgroundColor: theme.palette.error.dark }} />
                <Typography variant="h5" fontWeight={400}>
                  Analyze Weaknesses
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ ml: 7 }} spacing={1}>
                <Typography>with </Typography>
                <Typography color={theme.palette.primary.main}>Smart Reports</Typography>
                <Typography variant="h5" fontWeight="400"></Typography>
              </Stack>
              <Stack sx={{ ml: 6 }}>
                <Typography>Find out where and how to improve your scores</Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                <CustomAvatar
                  name="P"
                  sx={{
                    backgroundColor: theme.palette.error.dark,
                    color: theme.palette.common.white,
                  }}
                />
                <Typography variant="h5" fontWeight={400}>
                  Prepare Efficiently
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ ml: 7 }} spacing={1}>
                <Typography>with </Typography>
                <Typography color={theme.palette.primary.main}>Smart Review</Typography>
                <Typography variant="h5" fontWeight="400"></Typography>
              </Stack>

              <Stack sx={{ ml: 6 }}>
                <Typography>Find out what to study next to maximize score</Typography>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
