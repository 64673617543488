import axios from 'axios';
// config
import { API_BASE_URL } from '../config';

// ----------------------------------------------------------------------

const http = axios.create({ baseURL: API_BASE_URL });

http.interceptors.response.use(
  response => response,
  error => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default http;
