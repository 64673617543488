import { useRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, BoxProps, Button, Stack } from '@mui/material';
// hooks
import { Link as RouterLink } from 'react-router-dom';
import useOffSetTop from '@/hooks/useOffSetTop';
// import useResponsive from '@/hooks/useResponsive';
// utils
import { bgBlur } from '@/utils/cssStyles';
// config
// import { PATH_DOCS, PATH_MINIMAL_ON_STORE } from '@/routes/paths';
import { HEADER } from '@/config';
// routes
// components
import { Logo, LogoText } from '@/components/logo';
import { PATH_AUTH } from '@/routes/paths';
// import NavDesktop from './nav/desktop';
// import NavMobile from './nav/mobile';

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);

  const theme = useTheme();

  // const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Logo />
          <LogoText />

          <Box sx={{ flexGrow: 1 }} />

          {/* {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />} */}

          <Stack direction="row" spacing={1}>
            <Button variant="outlined" component={RouterLink} to={PATH_AUTH.register}>
              Join for free
            </Button>
            <Button variant="contained" component={RouterLink} to={PATH_AUTH.login}>
              Login
            </Button>
          </Stack>

          {/* {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />} */}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: theme => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
