// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, MenuItem, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { MdOutlineFlag } from 'react-icons/md';
import { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { bgBlur } from '@/utils/cssStyles';
import FormProvider, { RHFSelect, RHFTextField } from '@/components/hook-form';
import { FeedbackInputDto, SettingsApi } from '@/api';
import { useQuestionStore } from '@/stores/QuestionStore';
import { IconButtonAnimate } from '../animate';

type FormValuesProps = {
  type: string;
  qType: string;
  subject: string;
  detail: string;
};

export default function Feedback() {
  const theme = useTheme();

  const questionId = useQuestionStore(state => state.questionId);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const formSchema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    qType: Yup.string().when('type', {
      is: (val: string) => val === 'QUESTION',
      then: schema => schema.required('Question type is required'),
    }),
    subject: Yup.string().required('Subject is required'),
  });

  const defaultValues = {
    type: '',
    qType: '',
    subject: '',
    detail: '',
  };

  const methods = useForm<FormValuesProps>({ resolver: yupResolver(formSchema), defaultValues });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const { mutateAsync } = useMutation(
    async (data: FormValuesProps) =>
      await new SettingsApi().feedback(
        new FeedbackInputDto({ type: data.type, subject: data.subject, detail: data.detail })
      ),
    {
      onSuccess() {
        reset(defaultValues);
      },
    }
  );

  const onSubmit = async (data: FormValuesProps) => {
    let type = data.type;
    if (data.type === 'QUESTION') {
      type = `${data.type}:${data.qType}:${questionId}`;
    }
    await mutateAsync({
      type: type,
      detail: data.detail,
      subject: data.subject,
      qType: data.qType,
    });
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box sx={{ width: { xs: '350px', md: '500px' } }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5">Feedback</Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Stack spacing={3}>
                <RHFSelect name="type" label="Type">
                  <MenuItem value="GENERAL">General</MenuItem>
                  <MenuItem value="QUESTION">Question</MenuItem>
                </RHFSelect>
                {values.type === 'QUESTION' && (
                  <RHFSelect name="qType" label="Question Type">
                    <MenuItem value="ANSWER">Answer</MenuItem>
                    <MenuItem value="SOLUTION">Solution</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                  </RHFSelect>
                )}
                <RHFTextField name="subject" label="Subject" />
                <RHFTextField name="detail" multiline rows={3} label="Detail" />
                <Stack direction="row">
                  <Typography
                    variant="caption"
                    sx={{
                      alignItems: 'center',
                      display: 'inline-flex',
                      flexGrow: 1,
                    }}
                  >
                    For support: support@cetmap.com
                  </Typography>
                  <LoadingButton loading={isSubmitting} variant="contained" type="submit">
                    Submit
                  </LoadingButton>
                </Stack>
              </Stack>
            </Box>
          </FormProvider>
        </Box>
      </Popover>
      <Box
        sx={{
          p: 0.5,
          right: 24,
          bottom: 94,
          zIndex: 999,
          position: 'fixed',
          borderRadius: '50%',
          boxShadow: `-12px 12px 32px -4px ${alpha(
            theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
            0.36
          )}`,
          ...bgBlur({ color: theme.palette.background.default }),
        }}
      >
        <Tooltip title="Feedback" placement="top">
          <IconButtonAnimate color="primary" sx={{ p: 1.25 }} onClick={handleClick}>
            <MdOutlineFlag />
          </IconButtonAnimate>
        </Tooltip>
      </Box>
    </>
  );
}
