import { createContext, useCallback, useEffect, useReducer } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LoadingScreen from '@/components/loading-screen';
import { isValidToken, setSession } from '@/utils/session';
import http from '@/utils/http';

const initialState: AppStateType = {
  isInitialized: false,
  auth: undefined,
  localization: undefined,
};

export const AppContext = createContext<AppStateType>(initialState);
AppContext.displayName = 'AppContext';

type languageInfo = {
  name: string;
  displayName: string;
  icon: string;
  isDefault: boolean;
  isDisabled: boolean;
};

type localization = {
  languages: languageInfo[];
  CurrentLanguage: languageInfo;
};

type AppStateType = {
  isInitialized: boolean;
  auth: any;
  localization?: localization;
};

const reducer = (state: AppStateType, action: any) => {
  if (action.type === 'COMPLETED') {
    return {
      isInitialized: true,
      auth: action.payload.auth,
      localization: action.payload.localization,
    };
  }
  return state;
};

type AppProviderProps = {
  children: React.ReactNode;
};

export function AppProvider({ children }: AppProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
    }

    const response = await http.get('/AbpUserConfiguration/GetAll');

    localStorage.setItem('i18nextLng', response.data.result.localization.currentLanguage.name);

    dispatch({
      type: 'COMPLETED',
      payload: {
        auth: response.data.result.auth,
        localization: response.data.result.localization,
      },
    });
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // INITIALIZE i18next
  useEffect(() => {
    if (state.isInitialized) {
      const lng = state.localization.currentLanguage.name;
      i18n.use(initReactI18next).init({
        lng,
        fallbackLng: 'en',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
          escapeValue: false,
        },
      });
      i18n.addResources(lng, 'translations', state.localization.values.Athena);
    }
  }, [state]);

  if (state.isInitialized) {
    return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
  } else {
    return <LoadingScreen />;
  }
}
