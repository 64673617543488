import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
// routes
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Router } from '@/routes';
// theme
import ThemeProvider from '@/theme';
// locales
import ThemeLocalization from '@/locales';
// providers
import { AppProvider } from '@/app/AppContext';
import { AuthProvider } from '@/auth';

// components
import SnackbarProvider from '@/components/snackbar';
import { SettingsProvider, ThemeSettings } from '@/components/settings';
import { MotionLazyContainer } from '@/components/animate';
import ScrollToTop from '@/components/scroll-to-top';
import AppError from '@/components/app-error/AppError';

// fonts
import '@fontsource/public-sans/300.css';
import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/700.css';

// ----------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ErrorBoundary FallbackComponent={AppError}>
      <MotionLazyContainer>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <AuthProvider>
              <HelmetProvider>
                <SettingsProvider>
                  <BrowserRouter>
                    <ScrollToTop />
                    <ThemeProvider>
                      <ThemeSettings>
                        <ThemeLocalization>
                          <SnackbarProvider>
                            <Helmet titleTemplate="%s - CET Map" defaultTitle="CET Map">
                              <meta
                                name="description"
                                content="2023 CET Map helps you get the best directions to crack your CET."
                              />
                            </Helmet>
                            <Router />
                          </SnackbarProvider>
                        </ThemeLocalization>
                      </ThemeSettings>
                    </ThemeProvider>
                  </BrowserRouter>
                </SettingsProvider>
              </HelmetProvider>
            </AuthProvider>
          </AppProvider>
        </QueryClientProvider>
      </MotionLazyContainer>
    </ErrorBoundary>
  );
}
