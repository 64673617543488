// @mui
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Typography, Link } from '@mui/material';
// import { LogoText } from '@/components/logo';
import Image from '@/components/image';
// hooks
// import { useAuthContext } from '../../auth/useAuthContext';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
// import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuthContext();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
          <Image src={'/assets/images/emaillogo.png'} sx={{ mx: 'auto', height: '50px' }} />
        </Link>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="h4" sx={{ mr: 1 }}>
            Sign in
          </Typography>
          {/* <LogoText sx={{ fontSize: '1.5rem' }} /> */}
        </Stack>

        {/* <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link variant="subtitle2">Create an account</Link>
        </Stack> */}

        {/* <Tooltip title={method} placement="left">
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}
          />
        </Tooltip> */}
      </Stack>

      <AuthLoginForm />

      {/* <AuthWithSocial /> */}
    </LoginLayout>
  );
}
