// ICONS
import {
  MdHome,
  MdPeopleAlt,
  MdOutlinePeople,
  MdOutlineNoteAlt,
  MdBookmarks,
  MdQuiz,
  MdAssessment,
  MdAssignment,
  MdTipsAndUpdates,
} from 'react-icons/md';
import { FiTarget } from 'react-icons/fi';
import { PATH_DASHBOARD } from '@/routes/paths';
import { NavSection } from './types';

// ----------------------------------------------------------------------

const navConfig: NavSection[] = [
  {
    subheader: 'General',
    permission: 'Dashboard',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.home,
        icon: <MdHome size={24} />,
        permission: 'Dashboard',
      },
    ],
  },
  // ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Administration',
    permission: 'Administration',
    items: [
      {
        title: 'Users',
        path: PATH_DASHBOARD.admin.users.root,
        icon: <MdPeopleAlt size={24} />,
        permission: 'Administration.Users',
      },
      {
        title: 'Roles',
        path: PATH_DASHBOARD.admin.roles.root,
        icon: <MdOutlinePeople size={24} />,
        permission: 'Administration.Roles',
      },
    ],
  },

  // CET
  // ----------------------------------------------------------------------
  {
    subheader: 'Your CET MAP',
    permission: 'Learner',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.home,
        icon: <MdHome size={24} />,
        permission: 'Learner',
      },
      {
        title: 'MyGoals',
        path: PATH_DASHBOARD.myGoals,
        icon: <FiTarget size={24} />,
        permission: 'Learner',
      },
      {
        title: 'Assessments',
        path: PATH_DASHBOARD.assessments,
        icon: <MdOutlineNoteAlt size={24} />,
        permission: 'Learner',
      },
      {
        title: 'Reports',
        path: PATH_DASHBOARD.reports,
        icon: <MdAssessment size={24} />,
        permission: 'Learner',
      },
      {
        title: 'Reviews',
        path: PATH_DASHBOARD.reviews,
        icon: <MdAssignment size={24} />,
        permission: 'Learner',
      },
      {
        title: 'MyDoubts',
        path: PATH_DASHBOARD.myDoubt,
        icon: <MdQuiz size={24} />,
        permission: 'Learner',
      },
      {
        title: 'MyReviseList',
        path: PATH_DASHBOARD.myReviseList,
        icon: <MdBookmarks size={24} />,
        permission: 'Learner',
      },
      {
        title: 'TipOfDay',
        path: PATH_DASHBOARD.tipOfDay,
        icon: <MdTipsAndUpdates size={24} />,
        permission: 'Learner',
      },
    ],
  },
];

export default navConfig;
